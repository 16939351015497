// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accounting-services-js": () => import("./../../../src/pages/accounting-services.js" /* webpackChunkName: "component---src-pages-accounting-services-js" */),
  "component---src-pages-admin-and-maintenance-js": () => import("./../../../src/pages/admin-and-maintenance.js" /* webpackChunkName: "component---src-pages-admin-and-maintenance-js" */),
  "component---src-pages-artificial-intelligence-js": () => import("./../../../src/pages/artificial-intelligence.js" /* webpackChunkName: "component---src-pages-artificial-intelligence-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-data-solutions-js": () => import("./../../../src/pages/data-solutions.js" /* webpackChunkName: "component---src-pages-data-solutions-js" */),
  "component---src-pages-digitize-documents-js": () => import("./../../../src/pages/digitize-documents.js" /* webpackChunkName: "component---src-pages-digitize-documents-js" */),
  "component---src-pages-division-orders-js": () => import("./../../../src/pages/division-orders.js" /* webpackChunkName: "component---src-pages-division-orders-js" */),
  "component---src-pages-employment-application-js": () => import("./../../../src/pages/EmploymentApplication.js" /* webpackChunkName: "component---src-pages-employment-application-js" */),
  "component---src-pages-gis-mapping-js": () => import("./../../../src/pages/gis-mapping.js" /* webpackChunkName: "component---src-pages-gis-mapping-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mineral-management-js": () => import("./../../../src/pages/mineral-management.js" /* webpackChunkName: "component---src-pages-mineral-management-js" */),
  "component---src-pages-partnerships-js": () => import("./../../../src/pages/partnerships.js" /* webpackChunkName: "component---src-pages-partnerships-js" */),
  "component---src-pages-reports-and-dashboards-js": () => import("./../../../src/pages/reports-and-dashboards.js" /* webpackChunkName: "component---src-pages-reports-and-dashboards-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-vegetation-management-js": () => import("./../../../src/pages/vegetation-management.js" /* webpackChunkName: "component---src-pages-vegetation-management-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

